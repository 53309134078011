import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { environment } from '@environment/environment.dev';
import { CountryData, Filter, Project, ProjectResult, SectorialDatum, SectorialInfo, TopFive } from '@interfaces/project.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService { 

  private readonly _http = inject( HttpClient );
  private readonly _endpoint = environment.apiURL;
  
  public filters = signal<Filter[]>([
    { id: 1, label: 'Country', expanded: false, options: [] },
    { id: 2, label: 'Sector', expanded: false, options: [] },
    { id: 3, label: 'Duration', expanded: false, options: [] },
    { id: 4, label: 'Status', expanded: false, options: [] },
    { id: 5, label: 'Instruments', expanded: false, options: [] },
    { id: 6, label: 'Executing-agency', expanded: false, options: [] },
    { id: 7, label: 'Donors', expanded: false, options: [] },
  ]);
  public filterSidebar = signal<boolean>(false);
  public clearFilter = signal<boolean>(false);
  public sectorialAlignment = signal<SectorialDatum[]>([]);
  public countriesData = signal<CountryData[]>([]);
  public institution: any;
  public dataSourceUrl = signal<any>('');

  private sectorsUrl = `${this._endpoint}ListSectors`;
  private instrumentsUrl = `${this._endpoint}ListInstruments`;
  private agenciesUrl = `${this._endpoint}ListAgencies`;
  private donorsUrl = `${this._endpoint}ListDonors`;
  private countryNameToCode: { [key: string]: string } = {
    'Brazil': 'BR',
    'Colombia': 'CO',
    'Peru': 'PE',
    'Ecuador': 'EC',
    'Bolivia': 'BO',
    'Venezuela': 'VE',
    'Guyana': 'GY',
    'Suriname': 'SR'
  };

  public updateUrl(url: string) {
    this.dataSourceUrl.set(url);
  }

  public getDataSourceUrl() {
    return this.dataSourceUrl();
  }

  public getProjects() {
    return this._http.get<ProjectResult>(`${this._endpoint}GetProjects`);
  }

  public getProjectsFilter(filters: Filter[], currentPage: number) {
    const body: any = {
      pageNumber: currentPage - 1,
      pageSize: 3,
      countries: [],
      sectors: [],
      donors: [],
      statuses: [],
      instruments: [],
      agencies: [],
      startDate: null,
      endDate: null,
      startEndDate: null,
      endEndDate: null,
    };
  
    if (filters.length > 0) {
      filters.forEach(filter => {
        if (filter.options.length > 0) {
          switch (filter.label.toLowerCase()) {
            case 'country':
              body.countries = filter.options.map(country => this.countryNameToCode[country] || country);
              break;
            case 'sector':
              body.sectors = filter.options;
              break;
            case 'duration':
              body.startDate = filter.options[0];
              body.endDate = filter.options[1];
              body.startEndDate = filter.options[2];
              body.endEndDate = filter.options[3];
              break;
            case 'status':
              body.statuses = filter.options;
              break;
            case 'instruments':
              body.instruments = filter.options;
              break;
            case 'executing-agency':
              body.agencies = filter.options;
              break;
            case 'donors':
              body.donors = filter.options;
              break;
            default:
              break;
          }
        }
      });
    }
    return this._http.post<ProjectResult>(`${this._endpoint}GetProjectsFiltered`, body);
  }

  public getProjectsDownload(year: string) {
    return this._http.get<ProjectResult>(`${this._endpoint}GetProjects?year=${year}&limit=50000`);
  }

  public updateFilter(filters: Filter[]) {
    this.filters.update(() => {
      return [...filters];
    });
  }

  public clearProjectsFilter(clear: boolean) {
    this.clearFilter.set(clear);
  }

  public updateCountriesData(countriesData: CountryData[]) {
    this.countriesData.update(() => {
      return [...countriesData];
    });
  }

  public updateSectorialAlignment(sectorialAlignment: SectorialDatum[]) {
    this.sectorialAlignment.update(() => {
      return [...sectorialAlignment];
    });
  }

  public updateSidebarFilterStatus() {
    this.filterSidebar.update(() => {
      return !this.filterSidebar();
    });
  }

  public setSidebarFilterStatus(status: boolean) { 
    this.filterSidebar.set(status);
  }

  public getSectorialAlignment(startYear: number, endYear: number) { 
    return this._http.get<SectorialDatum[]>(`${this._endpoint}GetYearSectorialData?startYear=${startYear}&endYear=${endYear}`);
  }

  public getSectorialInfo(startYear: number, endYear: number) { 
    return this._http.get<SectorialInfo>(`${this._endpoint}GetTopYearSectorialData?startYear=${startYear}&endYear=${endYear}`);
  }

  public getProject(id: string): Observable<Project[]> {
    return this._http.get<Project[]>(`${this._endpoint}GetProjects?unqu_id=${id}`);
  }

  public getProjectsCarrousel() {
    return this._http.get<Project[]>(`${this._endpoint}GetCarousel`);
  }

  public getTopFive() {
    return this._http.get<TopFive[]>(`${this._endpoint}GetTop5`);
  }

  public getSectors() {
    return this._http.get<string[]>(this.sectorsUrl);
  }

  public getInstruments() {
    return this._http.get<string[]>(this.instrumentsUrl);
  }

  public getAgencies() { 
    return this._http.get<string[]>(this.agenciesUrl);
  }

  public getCountries() {
    return this._http.get<CountryData[]>(`${this._endpoint}GetCountryData`);
  }

  public getInstitutionData(filterName: string): Observable<Project[]> {
    const endpointWithFilter = `${this._endpoint}GetProjects?recipient=${filterName}`;
    return this._http.get<Project[]>(endpointWithFilter);
  }

  public getDonors(){
    return this._http.get<string[]>(this.donorsUrl);
  }

}
